<template>
  <div>
    <h4>
        Please check your information
      </h4>
      <div class="p-1 container-fluid">
        <div class="row mt-1">
          <div class="col-6 mb-1">
            <div class="text-muted">
              Name
            </div>
            <h4>{{ userRegistrationForm.firstName }} {{ userRegistrationForm.middleName }} {{ userRegistrationForm.lastName }}</h4>
          </div>
          <div class="col-6 mb-1">
            <div class="text-muted">
              Username
            </div>
            <h4>{{ userRegistrationForm.loginName }}</h4>
          </div>
          <div class="col-12 mb-1">
            <div class="text-muted">
              Email
            </div>
            <h4>{{ userRegistrationForm.email }}</h4>
          </div>
          <div class="col-6 mb-1">
            <div class="text-muted">
              Company Name
            </div>
            <h4>{{ companyRegistrationForm.companyName }}</h4>
          </div>
          <div class="col-6 mb-1">
            <div v-if="companyRegistrationForm.tenantType === TenantTypeEnum.SERVING.value" class="text-muted">
              Tenant ID
            </div>
            <h4 v-if="companyRegistrationForm.tenantType === TenantTypeEnum.SERVING.value">
              {{ companyRegistrationForm.tenantId }}
              </h4>
          </div>
          <div class="col-12 mb-1">
            <div class="text-muted">
              Warehouse Name
            </div>
            <h4>{{ companyRegistrationForm.warehouseName }}</h4>
          </div>
          <div class="col-6 mb-1">
            <div class="text-muted">
              Phone
            </div>
            <h4>{{ formattedPhoneNumber(companyRegistrationForm.phoneNumber) }}</h4>
          </div>
          <div class="col-6 mb-1">
            <div class="text-muted">
              Fax
            </div>
            <h4 v-if="companyRegistrationForm.faxNumber !== ''">
              {{ formattedPhoneNumber(companyRegistrationForm.faxNumber) }}
            </h4>
          </div>
          <div class="col-12 mb-1">
            <div class="text-muted">
              Address
            </div>
            <h4 class="">
              {{ createAddress(companyRegistrationForm) }}
            </h4>
          </div>
        </div>
      <div class="row mt-1">
        <dx-util-check-box :value="agreementApproved" text="I agree" @value-changed="updateApproval">
        </dx-util-check-box>
        <strong class="px-half">
          <a href="#" role="button" @click="openTermsPrivacy">Privacy Policy and Terms</a>
        </strong>
      </div>
      </div>
      <terms-privacy :component-id="termsPrivacyComponentId" />
  </div>
</template>

<script>
import { useCustomerRegistration } from '@/views/pages/authentication/register-form/useCustomerRegistration'
import { v4 as uuidv4 } from 'uuid'
import {
  ref, // watchEffect, // computed, watch, computed, reactive, onMounted, ref, watchEffect,
} from '@vue/composition-api'

export default {
  components: {
    'terms-privacy': () => import('./TermsPrivacy.vue'),
  },
  setup() {
    const { companyRegistrationForm, userRegistrationForm, TenantTypeEnum } = useCustomerRegistration()

    function nullCheck(value) {
      return value != null && value !== undefined ? value : ''
    }

    function createAddress(address) {
      return `${nullCheck(address.line1)} ${nullCheck(address.zipCode)} ${nullCheck(address.city)},
      ${nullCheck(address.state)}, ${nullCheck(address.country)}`
    }
    function formattedPhoneNumber(phoneNumber) {
      const formattedNumber = `+1 (${phoneNumber.toString().slice(0, 3)}) ${phoneNumber.toString().slice(3, 6)} ${phoneNumber.toString().slice(6)}`
      return formattedNumber
    }

    const termsPrivacyComponentId = ref('')
    function openTermsPrivacy() {
      termsPrivacyComponentId.value = uuidv4()
    }

    const agreementApproved = ref(true)

    return {
      companyRegistrationForm,
      userRegistrationForm,
      createAddress,
      formattedPhoneNumber,
      openTermsPrivacy,
      TenantTypeEnum,
      termsPrivacyComponentId,
      agreementApproved,
    }
  },
  methods: {
    updateApproval(e) {
      this.$emit('emit-agreement-accepted', e.value)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
